.container {
    margin-top: 3%;
    width: 100%;
}

.container p {
    color: #000000;
    text-align: center;
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 150px;
    border: 3px dashed #4aa1f3;
    border-radius: 16px;
}

.container-hover{
    background: linear-gradient(66deg, rgba(167,167,167,1) 0%, rgba(255,255,255,0.5550595238095238) 50%, rgba(167,167,167,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 150px;
    border: 3px dashed #4aa1f3;
    border-radius: 16px;
}

.upload-icon {
    width: 40px;
    height: 40px;
    background: url(../../assets/icons/upload.png) no-repeat center center; 
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.drop-message {
    text-align: center;
    color: #000000;
    font-family: Arial;
    font-size: 20px;
}

.small-message{
    text-align: center;
}

.cert-link-btn{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}