html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

}

.gradient-background{
    height: 100%;
    width: 100%;
    background: rgb(25,178,174) !important;
    background: linear-gradient(45deg, rgba(25,178,174,1) 0%, rgba(208,142,218,1) 100%) !important;
}

#root {
    height: 100%;
}

.dot {
    height: 250px;
    width: 250px;
    background-color: #F5F5F5;
    border-radius: 50%;
    display: inline-block;
    align-items: center;
    position: relative;
}

.dot img{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}