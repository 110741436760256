header{
    background: rgb(140,140,140);
    background: linear-gradient(90deg, rgba(140,140,140,0.34217436974789917) 0%, rgba(89,89,89,0.2861519607843137) 51%, rgba(102,102,102,0.17130602240896353) 100%);
    padding-top: 0%;
    min-height: 10px;
    max-height: 40px;
    border-bottom: 1px solid #d412123d;
}

body{
    margin: 0px;
    background-color: #f7f8fa;
 
}

.title-header{
    margin: 0px;
}

footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #006BB4;
    color:rgb(255, 255, 255);
    text-align: center;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

